import React from "react";
import "./LoadingPage.css";
const LoadingPage = () => {
  return (
    <div className="loading">
      <div className="loading_page">
        <p>l</p>
        <p>o</p>
        <p>a</p>
        <p>d</p>
        <p>i</p>
        <p>n</p>
        <p>g</p>
      </div>
    </div>
  );
};

export default LoadingPage;
